import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import {
  checkTwoFAStatus, setupTwoFA, enableTwoFAStatus, disableTwoFAStatus, updateTwoFAState,
} from '../../redux/auth/authActions';
import AddiSvg from '../../assets/images/additional-security.png';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function TwoFactorAuthentication() {
  const dispatch = useDispatch();

  const [userId, setUserId] = useState('');
  const [enableTfa, setEnableTfa] = useState('');
  const [disableTfa, setDisableTfa] = useState('');
  const [twoFALoader, setTwoFALoader] = useState(false);
  const [authEnableModal, setAuthEnableModal] = useState(false);
  const [authDisableModal, setAuthDisableModal] = useState(false);

  const twoFAStatus = useSelector((state) => state.auth?.twoFAStatus);
  const twoFAQRImage = useSelector((state) => state.auth?.twoFAQRImage);
  const twoFAQRImageFetched = useSelector((state) => state.auth?.twoFAQRImageFetched);
  const twoFAStatusFetched = useSelector((state) => state.auth?.twoFAStatusFetched);
  const twoFAStatusChanged = useSelector((state) => state.auth?.twoFAStatusChanged);
  const twoFAError = useSelector((state) => state.auth?.twoFAError);

  useEffect(() => {
    setTwoFALoader(true);
    const loginUser = localStorage.getItem('userId');
    const uId = JSON.parse(loginUser);
    setUserId(uId);
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(checkTwoFAStatus(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (twoFAStatusFetched) {
      setTwoFALoader(false);
      dispatch(updateTwoFAState());
    }
  }, [twoFAStatusFetched]);

  useEffect(() => {
    if (twoFAQRImageFetched || twoFAError) {
      setTwoFALoader(false);
      dispatch(updateTwoFAState());
    }
  }, [twoFAQRImageFetched, twoFAError]);

  const handleAuthEnableModalClose = () => setAuthEnableModal(false);
  const handleAuthDisableModalClose = () => setAuthDisableModal(false);

  const handleAuthModalShow = () => {
    if (twoFAStatus) {
      setAuthDisableModal(true);
    } else {
      setTwoFALoader(true);
      dispatch(setupTwoFA(userId));
      setAuthEnableModal(true);
    }
  };

  const enableTwoFA = () => {
    setTwoFALoader(true);
    const data = {
      userId,
      one_time_password: enableTfa,
    };
    dispatch(enableTwoFAStatus(data));
    setEnableTfa('');
  };

  const disableTwoFA = () => {
    setTwoFALoader(true);
    const data = {
      userId,
      one_time_password: disableTfa,
    };
    dispatch(disableTwoFAStatus(data));
    setDisableTfa('');
  };

  useEffect(() => {
    if (twoFAStatusChanged) {
      setTwoFALoader(false);
      dispatch(updateTwoFAState());
      handleAuthEnableModalClose();
      handleAuthDisableModalClose();
    }
  }, [twoFAStatusChanged]);

  return (
    <>
      {twoFALoader ? (<FullPageTransparentLoader />) : null}
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h4 className="mb-3 text-capilatize">Additional Security</h4>
          <div className="auto-lock d-flex align-items-center">
            <div className="img-wrapper">
              <img src={AddiSvg} alt="" className="img-fluid" />
            </div>
            <div className="auto-lock-content">
              <h2>Auto-Lock</h2>
              {/* <button className="btn btn-warning" onClick={handleShow}>Enable</button> */}
              {!twoFALoader
                ? (
                  <>
                    <div className="text-success mb-2">
                      {twoFAStatus === true ? 'Deactivate' : 'Activate'}
                      Google Authentication
                    </div>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={handleAuthModalShow}
                    >
                      {twoFAStatus === true ? 'Deactivate 2FA' : 'Activate 2FA'}

                    </button>
                  </>
                ) : null}
            </div>
          </div>
        </div>
      </div>

      <Modal show={authEnableModal} onHide={handleAuthEnableModalClose} className="widthdrawal-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-data">
            <p>Configure your 2FA by scanning the QR CODE below.</p>
            {twoFAQRImage
              ? (
                <div className="mb-4">
                  <img src={twoFAQRImage} alt="img" />
                </div>
              )
              : null}
            <p>Activate Authenticator and enter generated code in a field below</p>
            <input
              type="text"
              className="form-control mb-5"
              onChange={(e) => setEnableTfa(e.target.value)}
              name="enableTfa"
              value={enableTfa}
              max={6}
              placeholder="Enter 6 Digits OTP"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAuthEnableModalClose}>Close</Button>
          <Button variant="primary" onClick={enableTwoFA} type="submit">Confirm</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={authDisableModal} onHide={handleAuthDisableModalClose} className="widthdrawal-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-white">Disable Two Factor Authentication</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-data">
            <p>Enter 2FA code to disable 2FA protection</p>
            <input
              type="text"
              className="form-control mb-5"
              onChange={(e) => setDisableTfa(e.target.value)}
              name="disableTfa"
              value={disableTfa}
              max={6}
              placeholder="Enter 6 Digits OTP"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAuthDisableModalClose}>Close</Button>
          <Button variant="primary" onClick={disableTwoFA} type="submit">Confirm</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TwoFactorAuthentication;
