import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDebounce } from '../../hooks/useDebounce';
import { getClientActivities, exportClientActivityToFile } from '../../redux/clientActivity/clientActivityActions';
import { getActivityClientTypes } from '../../redux/activityClientType/activityClientTypeActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import ClientLoginActivity from './ClientLoginActivity';
import { ClientActivityColumns } from '../../DataTableSchemas/ClientActivityColumns';

function ClientActivity() {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/client_pagination';

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  
  const [userFilters, setUserFilters] = useState({});
  const [crmUsersFilter, setCrmUsersFilter] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [activityTypeFilter, setActivityTypeFilter] = useState([]);

  const allClientActivities = useSelector((state) => state?.clientActivity?.clientActivities);
        
  const setStoredPagination = () => {
    const clientLoginActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (clientLoginActivityRowsJSON) {
      const filterRows = JSON.parse(clientLoginActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const getActivities = (page, rowsPerPage, userFilters) => dispatch(getClientActivities({ page, rowsPerPage, userFilters }));
  
  const handlePageChange = (page) => setPage(page);
  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };
  
  const handleExportClientActivity = async (fileType) => {
    await dispatch(exportClientActivityToFile({
      page, 
      rowsPerPage,
      filters: userFilters,
      fileType, 
    }));
  };

  useDebounce(clientNameFilter, 1000, (value) => {
    if (value.length >= 3 || value.length === 0) {
      setUserFilters({ ...userFilters, clientName: value });
    }
  });

  const activityColumns = ClientActivityColumns(
    userFilters, 
    setUserFilters,
    crmUsersFilter, 
    setCrmUsersFilter,
    activityTypeFilter,
    setActivityTypeFilter,
    clientNameFilter,
    setClientNameFilter,
  );

  useEffect(() => {
    setLoader(true);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const roleId = data?.roleId;
    const uid = data?._id;

    setStoredPagination();
    
    Promise.allSettled([
      dispatch(getActivityClientTypes()),
      dispatch(getSubCRMUsers(roleId, uid)),
    ]);

    setLoader(false);
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters);
      setTableLoading(false);
    }
  }, [isPaginationDT, userFilters, page, rowsPerPage]);

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>Clients Activity Log</h3>
            <div className="d-flex">
              <Dropdown className="leads-download-dropdown ms-1">
                <Dropdown.Toggle variant="" className="btn-default" id="dropdown-basic">
                  Download
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="import-csv-btn text-center mb-1">
                    <button type="button" className="btn btn-default" onClick={() => handleExportClientActivity('xlsx')}>Excel</button>
                  </div>
                  <div className="import-csv-btn text-center">
                    <button type="button" className="btn btn-default" onClick={() => handleExportClientActivity('csv')}>CSV</button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
              {
                isPaginationDT
                  && (
                    <DataTable
                      columns={activityColumns}
                      data={allClientActivities.paginatedResult}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationTotalRows={allClientActivities.total}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
                }
            </div>
            <ClientLoginActivity />
          </div>
        </div>
      )
  );
}

export default ClientActivity;
