import React, {
  useState, useRef, useEffect, useImperativeHandle, forwardRef, 
} from 'react';
import {
  Dropdown, Modal, Button, Row, Col, 
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

export const DatatableFilters = forwardRef(({
  filters, setFilters, createFilter, deleteFilter, updateFilter, storageKey, pathname, 
}, ref) => {
  const [isModalShow, setModalShown] = useState(false);
  const [dropdownName, setDropdownName] = useState('Select');
  const filterNameRef = useRef('');

  useImperativeHandle(ref, () => ({
    clearDrodownName() {
      setDropdownName('Select');
    },
    handleAfterCreate() {
      setDropdownName(filterNameRef.current.value);
      filterNameRef.current.value = '';
      setModalShown(false);
    },
  }));

  const selectFilter = (filter) => {
    setDropdownName(filter.name);
    setFilters(filter);
  };

  const onCreate = () => {
    if (!filterNameRef.current.value.length) {
      toast.error('Enter a Filter Name.', {
        autoClose: 1000,
      });

      return;
    }

    createFilter(filterNameRef.current.value);
  };

  useEffect(() => {
    const storageId = localStorage.getItem(storageKey);

    if (storageId && filters.length) {
      const id = JSON.parse(storageId);

      filters.forEach((filter) => {
        if (filter._id === id) setDropdownName(filter.name);
      });
    }
  }, [filters]);

  return (
    <>
      <Dropdown className="dt-actions__dropdown ms-1" autoClose="outside">
        <Dropdown.Toggle 
          variant="" 
          className="btn-default" 
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon 
            icon={faFilter} 
            size="xs" 
            style={{ 
              padding: '5px', 
              marginRight: '5px', 
              borderRadius: '50%', 
            }}
          />
          Filters
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown className="dt-actions__dropdown secondary">
            <Dropdown.Toggle variant="secondary" className="dt-filters__filters-selector">
              <span>{dropdownName}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {filters.map((filter) => (
                pathname === filter.pathname && <Dropdown.Item className="truncate-text" onClick={() => selectFilter(filter)} key={filter._id}>{filter.name}</Dropdown.Item>
              ))}
              <Dropdown.Item onClick={() => setModalShown(true)}>Add New</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="dt-filters__btn-row">
            <button type="button" className="btn-default" onClick={updateFilter}>Save</button>
            <button type="button" className="btn-default bg-red" onClick={deleteFilter}>Delete</button>
          </div>
        </Dropdown.Menu>
      </Dropdown>

      <Modal show={isModalShow} onHide={() => setModalShown(false)} className="widthdrawal-modal">
        <Modal.Header closeButton>
          <Modal.Title className="text-white">CREATE NEW FILTER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="withdrawal-data">
            <Row>
              <Col md={12}>
                <div className="form-group me-2">
                  <label className="control-label mb-2">Filter Name</label>
                  <div className="input-wrapper">
                    <input autoComplete="off" type="text" ref={filterNameRef} className="form-control" name="filterName" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShown(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onCreate}>Add</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
