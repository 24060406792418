import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export function DownloadFile({ handleExport }) {
  return (
    <Dropdown className="leads-download-dropdown ms-1">
      <Dropdown.Toggle 
        variant="" 
        className="btn-default" 
        id="dropdown-basic"
        style={{ 
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        <FontAwesomeIcon
          icon={faDownload}
          size="xs"
        />
        Download
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="import-csv-btn text-center mb-1">
          <button type="button" className="btn btn-default" onClick={() => handleExport('xlsx')}>Excel</button>
        </div>
        <div className="import-csv-btn text-center">
          <button type="button" className="btn btn-default" onClick={() => handleExport('csv')}>CSV</button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};
