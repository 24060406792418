import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  faCheck, faColumns, faTimes, faRefresh, 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { createCRMFilter, deleteCRMFilter, updateCRMFilter } from '../../redux/crmUser/crmUserActions';
import { KycAmlColumns } from '../../DataTableSchemas/KycAmlColumns';
import {
  selectIsKycAmlDataLoading,
  selectKycAmlData,
  selectKycAmlDataTotalCount,
  selectUserNameSearchFilter,
} from '../../redux/kycAml/kycAmlSelectors';
import {
  getKycAmlData, 
  getKycStatuses, 
  setTimeUploadedFilterCreator,
  setUserNameFilterCreator,
  setVerifyStatusFilterCreator, 
} from '../../redux/kycAml/kycAmlActions';
import { kycColumns } from '../../columnsDefaultConfig/KycColumns';
import { DatatableFilters } from '../../components/DatatableFilters/DatatableFilters';
import styles from './styles.module.css';
import { useDebounce } from '../../hooks/useDebounce';

function KycAml() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const datatableFiltersRef = useRef(null);

  const paginationStorageName = 'DataTable_kyc_aml/listing_pagination';
  const columnsStorageName = 'DataTable_kyc_aml/columns';
  const filtersStorageName = 'DataTable_kyc_aml/filters'; 
  const filterIdStorageName = 'DataTable_kyc_aml/filters_id';
  const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
  const kycColumnsJSON = localStorage.getItem(columnsStorageName);
  const kycStorageFiltersJSON = localStorage.getItem(filtersStorageName);

  const kycAmlData = useSelector(selectKycAmlData);
  const isKycAmlDataLoading = useSelector(selectIsKycAmlDataLoading);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);

  const nameSearchFilter = useSelector(selectUserNameSearchFilter);
  
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectKycAmlDataTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [columnConfig, setColumnConfig] = useState(kycColumns);
  const [kycQuery, setKycQuery] = useState({});

  const setStoredPagination = () => {
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  const columnsSetup = KycAmlColumns({ columnConfig, setKycQuery, kycQuery });

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && kycQuery[key] !== value) {
      setKycQuery((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(nameSearchFilter, 1000, (value) => debounceCallback({ value, key: 'fullName' }));

  const refresh = async () => {
    dispatch(getKycAmlData(page, rowsPerPage, kycQuery));
  };

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredColumnsData = () => {
    if (kycColumnsJSON) {
      const columns = JSON.parse(kycColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const handleClear = () => {
    dispatch(setUserNameFilterCreator(null));
    dispatch(setVerifyStatusFilterCreator([]));
    dispatch(setTimeUploadedFilterCreator([]));
    setKycQuery({});

    localStorage.removeItem(filtersStorageName);
    localStorage.removeItem(filterIdStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const setCRMFilters = (filter) => {
    const { fullName, status, timeOptionIds } = filter;

    setKycQuery({
      fullName,
      status,
      'time uploaded': timeOptionIds,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const storageFilters = localStorage.getItem(filtersStorageName);
    const storageUserId = localStorage.getItem('userId');
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(storageFilters);

    const data = {
      name,
      crmUserId,
      pathname,
      fullName: filters.fullName || '',
      status: filters.status || [],
      timeOptionIds: filters['time uploaded'] || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    const storageFilterId = localStorage.getItem(filterIdStorageName);

    if (storageFilterId) {
      const id = JSON.parse(storageFilterId);
      const storageFilters = localStorage.getItem(filtersStorageName);
      const filters = JSON.parse(storageFilters);
      const data = {
        fullName: filters.fullName || '',
        status: filters.status || [],
        timeOptionIds: filters['time uploaded'] || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const setStoredFilterData = () => {
    if (kycStorageFiltersJSON) {
      const filters = JSON.parse(kycStorageFiltersJSON);
      setKycQuery(filters || {});

      dispatch(setUserNameFilterCreator(filters.fullName || null));
      dispatch(setTimeUploadedFilterCreator(filters['time uploaded'] || []));
      dispatch(setVerifyStatusFilterCreator(filters.status || []));
    }
  };

  useEffect(() => {
    setStoredPagination();
    setStoredColumnsData();
    setStoredFilterData();
    dispatch(getKycStatuses());
  }, []);

  useEffect(() => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(kycQuery));
      dispatch(getKycAmlData(page, rowsPerPage, kycQuery));
    }
  }, [page, rowsPerPage, kycQuery, isPaginationDT]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>KYC/AML</h3>

        <div className="action__btn-row">
          {crmFilters && (
            <DatatableFilters 
              ref={datatableFiltersRef}
              filters={crmFilters} 
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            /> 
          )}
          <Dropdown className="leads-columns-dropdown ms-1">
            <Dropdown.Toggle 
              variant="" 
              className="btn-default"
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon
                icon={faColumns}
                size="xs"
              />
              Columns
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <ul className="leads-columns-list">
                {columnConfig.map((column) => (
                  <li 
                    className="leads-columns-option"
                    key={column.name}
                    onClick={() => toggleColumn(column.name)}
                  >
                    {column.selected ? (
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="#6E7F95"
                      />
                    ) : null}
                    <span className="leads-columns-option__name">{column.name}</span>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>

          <button 
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={handleClear}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
            Clear
          </button>

          <button 
            type="button"
            className={`btn btn-default ms-1 ${styles.mainButton}`}
            onClick={refresh}
          >
            <FontAwesomeIcon icon={faRefresh} size="sm" />
            Refresh
          </button>

        </div>

        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={columnsSetup}
                  data={kycAmlData}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  theme="solarizedd"
                  progressPending={isKycAmlDataLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default KycAml;
