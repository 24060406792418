import { GET_SAVING_INVESTMENTS, DELETE_SAVING_INVESTMENT } from './savingInvestmentsTypes';

// eslint-disable-next-line default-param-last
export default (state = [], action) => {
  switch (action.type) {
    case GET_SAVING_INVESTMENTS:
      return action.payload;
    case DELETE_SAVING_INVESTMENT:
      return state.filter((item) => item._id !== action.payload);
    default:
      return state;
  }
};
