import React from 'react';
import { OrdersHistoryTable } from '../../components/OrdersHistoryTable/OrdersHistoryTable';

function OrdersHistory() {
  const filtersStorageName = 'DataTable_orders/order-history_filters';
  const filterIdStorageName = 'DataTable_orders/order-history_filter_id'; 
  const columnsStorageName = 'DataTable_orders/order-history_columns';
  const paginationStorageName = 'DataTable_orders/order-history_pagination';
  
  const localStorageConfig = {
    filtersStorageName,
    filterIdStorageName,
    columnsStorageName,
    paginationStorageName,
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Order History</h3>
        <OrdersHistoryTable localStorageConfig={localStorageConfig} />
      </div>
    </div>
  );
}

export default OrdersHistory;
