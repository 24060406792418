import React, {
  useEffect, useState, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { SmtpPresetsColumns } from '../../DataTableSchemas/SmtpPresetsColumns';
import { ModalContext } from '../../context';
import { useMultiDebounce } from '../../hooks/useMultiDebounce';
import {
  selectIsSmtpPresetsLoading, selectSmtpAgentSearchFilter, selectSmtpDescriptionSearchFilter, selectSmtpEmailSearchFilter, selectSmtpPasswordSearchFilter, selectSmtpPresets, selectSmtpProviderSearchFilter, selectTotalCount, 
} from '../../redux/smtpPresets/smtpPresetsSelectors';
import { fetchSmtpPresets } from '../../redux/smtpPresets/smtpPresetsActions';
import { CreateSmtpPreset } from './CreateSmtpPreset/CreateSmtpPreset';

function SmtpPresets() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const paginationStorageName = 'DataTable_smtpPresets/listing_pagination';

  // templates variables
  const smtpPresets = useSelector(selectSmtpPresets);
  const isSmtpPresetsLoading = useSelector(selectIsSmtpPresetsLoading);

  // filters variables
  const agentSearchFilter = useSelector(selectSmtpAgentSearchFilter);
  const providerSearchFilter = useSelector(selectSmtpProviderSearchFilter);
  const emailSearchFilter = useSelector(selectSmtpEmailSearchFilter);
  const passwordSearchFilter = useSelector(selectSmtpPasswordSearchFilter);
  const descriptionSearchFilter = useSelector(selectSmtpDescriptionSearchFilter);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create SMTP configuration</h3>,
      bodyContent: <CreateSmtpPreset hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  useEffect(() => {
    dispatch(fetchSmtpPresets(page, rowsPerPage, {
      agent: agentSearchFilter,
      provider: providerSearchFilter,
      email: emailSearchFilter,
      password: passwordSearchFilter,
      description: descriptionSearchFilter,
    }));
  }, [page, rowsPerPage, agentSearchFilter]);

  useMultiDebounce([
    providerSearchFilter, 
    emailSearchFilter, 
    passwordSearchFilter,
    descriptionSearchFilter,
  ], 500, () => {
    if (providerSearchFilter !== null || emailSearchFilter !== null || passwordSearchFilter !== null || descriptionSearchFilter !== null) {
      dispatch(fetchSmtpPresets(page, rowsPerPage, {
        agent: agentSearchFilter,
        provider: providerSearchFilter,
        email: emailSearchFilter,
        password: passwordSearchFilter,
        description: descriptionSearchFilter,
      }));
    }
  });

  useEffect(() => {
    setStoredPagination();
  }, []);

  const SmtpPresetsCol = SmtpPresetsColumns();

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>SMTP Configuration</h3>
        <button 
          type="button" 
          style={{ backgroundColor: 'green' }}
          className="btn btn-default btn-lg me-1 p-1"
          onClick={handleCreateBtn}
        >
          + Add
        </button>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={SmtpPresetsCol}
                  data={smtpPresets}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isSmtpPresetsLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default SmtpPresets;
