import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { getAdminAddress, editAdminAddress, updateState } from '../../redux/adminAddress/adminAddressActions';
import { getCryptoCurrency } from '../../redux/currency/currencyActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function EditAdminAddress() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const [loader, setLoader] = useState(false);
  const [selectedCurr, setSelectedCurr] = useState([]);
  const [currencyErr, setCurrError] = useState('');
  const [adminAddressData, setAdminAddressData] = useState({ address: '', currencyId: '' });

  const adminAddress = useSelector((state) => state?.adminAddress?.adminAddress);
  const adminAddressEditted = useSelector((state) => state?.adminAddress?.adminAddressEditted);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);


  const {
    register, handleSubmit, reset, control, formState: { errors },
  } = useForm({
    defaultValues: adminAddressData,
  });

  const options = useMemo(() => {
    if (!currencies && !adminAddressData?.currencyId) return [];
  
    return currencies?.map((currency) => ({
      key: currency?._id,
      value: currency?._id,
      label: currency?.name,
    }));
  }, [currencies, adminAddressData]);

  useEffect(async () => {
    setLoader(true);
    await Promise.all([
      dispatch(getAdminAddress(id)),
      dispatch(getCryptoCurrency()),
    ]);
    setLoader(false);
  }, []);

  useEffect(() => {
    if (Object.keys(adminAddress)?.length > 0) {
      setAdminAddressData(adminAddress);
      reset(adminAddress);

      if (adminAddress?.currency?._id) {
        setSelectedCurr(
          {
            key: adminAddress?.currency?._id,
            value: adminAddress?.currency?._id,
            label: adminAddress?.currency?.name,
          },
        ); 
      }
    }
  }, [adminAddress]);

  useEffect(() => {
    if (adminAddressEditted) {
      dispatch(updateState());
      history.goBack();
    }
  }, [adminAddressEditted]);

  const editAdminAddressData = {
    address: {
      required: 'address is required',
    },
    currencyId: {
      required: 'Please select currency',
    },
  };

  const handleSave = async (formData) => {
    if (!formData || !formData.address) return;
    if (!selectedCurr || !selectedCurr.value) {
      setCurrError(adminAddressData.currencyId.required);

      return;
    }

    const data = {
      address: formData.address,
      currencyId: selectedCurr.value,
    };

    dispatch(editAdminAddress(id, data));
  };

  const selectCurrency = (option) => {
    setCurrError('');
    setSelectedCurr(option);
  };

  const colourStyles = {
    control: (styles, { isSelected }) => ({
      ...styles,
      background: '#374057',
      color: '#fff',
      border: '1px solid #374057',
      boxShadow: isSelected ? 'none' : 'none',
      borderColor: isSelected ? '#374057' : '#374057',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    input: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#fff',
    }),
    menuList: (styles) => ({
      ...styles,
      background: '#374057',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? '#16202e'
        : isSelected
          ? '#16202e'
          : undefined,
      color: '#fff',
      cursor: 'pointer',
      zIndex: 1,
      '&:hover': {
        background: '#16202e',
      },
    }),
  };

  return (
    loader ? (<FullPageTransparentLoader />)
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <FontAwesomeIcon className="faArrowLeftIcon" icon={faArrowLeft} onClick={() => history.goBack()} />
            <h5>Edit Admin Wallet</h5>
            <form onSubmit={handleSubmit(handleSave)}>
              <div className="form-group col-md-12">
                <label className="control-label">Wallet Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Wallet Address"
                  {...register('address', editAdminAddressData.address)}
                  name="address"
                  // eslint-disable-next-line react/no-unknown-property
                  control={control}
                />
                {errors?.address && <span className="errMsg">{errors.address.message}</span>}
              </div>
              <div className="form-group col-md-12 pt-2 custom-milti-select">
                <label className="control-label">Select Currencies</label>
                <Select 
                  styles={colourStyles}
                  value={selectedCurr}
                  name="options" 
                  options={options} 
                  onChange={(option) => selectCurrency(option)}
                />
                {currencyErr ? (<span className="errMsg">{currencyErr}</span>) : ('')}
              </div>
              <div>
                <button className="btn btn-default" type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
      )
  );
}

export default EditAdminAddress;
