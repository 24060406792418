/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { getUserSavingInvestments, getSavingInvestments, deleteSavingInvestment } from '../redux/savingInvestment/savingInvestmentActions';
import { CopyToClipboardCmp } from '../components';

export function SavingInvestmentsDatatable({ userId = null }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const investments = useSelector((state) => state.stakingInvestments);

  useEffect(async () => {
    setIsLoading(true);
    await dispatch(userId ? getUserSavingInvestments(userId) : getSavingInvestments());
    setIsLoading(false);
  }, [userId]);

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this record?',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then(({ isConfirmed }) => {
        if (isConfirmed) dispatch(deleteSavingInvestment(id));
      });
  };

  const columns = [
    {
      name: 'Id',
      cell: ({ _id }) => <CopyToClipboardCmp label={_id} isNeedToCut />,
      width: '150px',
    },
    {
      name: 'User',
      cell: ({ user }) => (user ? <Link className="text-white" to={`/user-detail/${user._id}`}>{`${user.firstName} ${user.lastName}`}</Link> : 'User Not Found'),
      omit: !!userId,
      width: '250px',
    },
    {
      name: 'Invested Amount',
      cell: ({ investedAmount, currency }) => <span>{`${investedAmount.toFixed(2)} ${currency.symbol}`}</span>,
      width: '200px',
    },
    {
      name: 'Final Earning',
      cell: ({ finalEarnings, currency }) => (finalEarnings ? <span>{`${finalEarnings.toFixed(2)} ${currency.symbol}`}</span> : <FontAwesomeIcon icon={faMinus} />),
      width: '200px',
    },
    {
      name: 'Period',
      cell: ({ period, isFlexible }) => `${!isFlexible ? `Locked ${period}` : period}`,
      width: '200px',
    },
    {
      name: 'Percent',
      cell: ({ pct }) => `${pct} %`,
    },
    {
      name: 'Start Date',
      cell: ({ createdAt }) => moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
      width: '250px',
    },
    {
      name: 'Release Date',
      cell: ({ releaseTime }) => (releaseTime ? moment(releaseTime).format('YYYY-MM-DD HH:mm:ss') : <FontAwesomeIcon icon={faMinus} />), 
      width: '250px',
    },
    {
      name: 'Flexible',
      cell: ({ isFlexible }) => <FontAwesomeIcon icon={isFlexible ? faCheck : faMinus} />,
    },
    {
      name: 'Closed',
      cell: ({ isReleased }) => <FontAwesomeIcon icon={isReleased ? faCheck : faMinus} />,
    },
    {
      name: 'Actions',
      cell: ({ _id }) => <button type="button" className="btn btn-danger btn-sm" onClick={() => handleDelete(_id)}>Delete</button>,
      width: '250px',
    },
  ];

  return (
    <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
      <DataTable
        columns={columns} 
        data={investments} 
        pagination 
        paginationRowsPerPageOptions={[10, 20, 50, 100]} 
        theme="solarizedd"
        progressPending={isLoading}
        progressComponent={<div className="datatable-loader__background" />}
      />
    </div>
  );
};
