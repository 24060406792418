import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useDebounce } from '../../hooks/useDebounce';
import { getCRMActivities, exportCRMActivityToFile } from '../../redux/crmActivity/crmActivityActions';
import { getActivityTypes } from '../../redux/activityType/activityTypeActions';
import { getSubCRMUsers } from '../../redux/crmUser/crmUserActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { CRMActivityColumns } from '../../DataTableSchemas/CRMActivityColumns';

function CRMActivity() {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/crm_pagination';

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const [userFilters, setUserFilters] = useState({});
  const [crmUsersFilter, setCrmUsersFilter] = useState([]);
  const [crmActivityTypeFilter, setCrmActivityTypeFilter] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState(null);

  const allCRMActivities = useSelector((state) => state?.crmActivity?.crmActivities);
  
  const getActivities = (page, rowsPerPage, userFilters) => dispatch(getCRMActivities({ page, rowsPerPage, userFilters }));

  const setStoredPagination = () => {
    const crmActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (crmActivityRowsJSON) {
      const filterRows = JSON.parse(crmActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const handlePageChange = async (page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = async (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
    setRowsPerPage(currentRowsPerPage);
  };

  const handleExportCRMActivity = async (fileType) => {
    await dispatch(exportCRMActivityToFile(fileType, page, rowsPerPage, userFilters));
  };

  useDebounce(clientNameFilter, 1000, (value) => {
    if (value.length >= 3 || value.length === 0) {
      setUserFilters({ ...userFilters, clientName: value });
    }
  });

  const crmActivityCol = CRMActivityColumns(
    userFilters, 
    setUserFilters,
    crmUsersFilter,
    setCrmUsersFilter,
    crmActivityTypeFilter, 
    setCrmActivityTypeFilter,
    clientNameFilter,
    setClientNameFilter,
  );

  useEffect(() => {
    setLoader(true);
    const loginData = localStorage.getItem('user');
    const data = JSON.parse(loginData);
    const roleId = data?.roleId;
    const uid = data?._id;

    setStoredPagination();

    Promise.allSettled([
      dispatch(getActivityTypes()),
      dispatch(getSubCRMUsers(roleId, uid)),
    ]);

    setLoader(false);
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters);
      setTableLoading(false);
    }
  }, [isPaginationDT, page, rowsPerPage, userFilters]);

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>CRM Users Activity Log</h3>
            <div className="d-flex">
              <Dropdown className="leads-download-dropdown ms-1">
                <Dropdown.Toggle variant="" className="btn-default" id="dropdown-basic">
                  Download
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="import-csv-btn text-center mb-1">
                    <button type="button" className="btn btn-default" onClick={() => handleExportCRMActivity('xlsx')}>Excel</button>
                  </div>
                  <div className="import-csv-btn text-center">
                    <button type="button" className="btn btn-default" onClick={() => handleExportCRMActivity('csv')}>CSV</button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
              {
                isPaginationDT
                  && (
                    <DataTable
                      columns={crmActivityCol}
                      data={allCRMActivities.paginatedResult}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationTotalRows={allCRMActivities.total}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
                }
            </div>
          </div>
        </div>
      )
  );
}

export default CRMActivity;
