import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';

export function AssetModalFilter({
  currencies, columnName, setFilters, filters,
}) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);
  const [filtrText, setFilterText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const handleFilter = () => {
    const filteredItemsResult = currencies?.filter((item) => item?.symbol.toLowerCase().includes(filtrText.toLowerCase()));
    if (filteredItemsResult) {
      setFilteredItems([...filteredItemsResult]);
    }
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setFilters([...filters, value]);
    if (!checked) {
      setFilters(filters.filter((item) => item !== value));
    }
  };

  const handleSelectAll = () => {
    setFilters(currencies?.map((item) => item.symbol));
  };

  const handleClearAll = () => {
    setFilters([]);
  };

  useEffect(() => {
    handleFilter();
  }, [filtrText]);

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => {
              setIsShownDropDown(false);
              setFilterText('');
            }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Filter"
            value={filtrText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button type="button" onClick={handleSelectAll}>
            Check all
          </button>
          <button type="button" onClick={handleClearAll}>
            Uncheck all
          </button>
          <div
            style={{
              marginTop: '5px',
              width: '100%',
              alignItems: 'start',
              maxHeight: '150px',
              overflow: 'auto',
            }}
          >
            {filteredItems?.map((item) => (
              <div
                style={{
                  margin: '5px',
                  fontSize: '12px',
                }}
              >
                <input
                  key={item?._id}
                  name={item?.symbol}
                  type="checkbox"
                  value={item?.symbol}
                  checked={filters.includes(item.symbol)}
                  onClick={(e) => handleClick(e)}
                />
                <label htmlFor={item?.symbol} style={{ marginLeft: '3px' }}>
                  {item?.symbol}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : (
        null
      )}
    </div>
  );
}

export function ManagerModalFilter({
  managers, columnName, setFilters, filters,
}) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);
  const [filtreText, setFilterText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  const handleFilter = () => {
    const filteredItemsResult = managers?.filter(
      (item) => item?.email.toLowerCase().includes(filtreText.toLowerCase())
        || item?.firstName.toLowerCase().includes(filtreText.toLowerCase())
        || item?.lastName.toLowerCase().includes(filtreText.toLowerCase())
        || item?.username.toLowerCase().includes(filtreText.toLowerCase()),
    );

    setFilteredItems([...filteredItemsResult]);
  };

  const handleClick = (e) => {
    const { value, checked } = e.target;
    setFilters([...filters, value]);
    if (!checked) {
      setFilters(filters.filter((item) => item !== value));
    }
  };

  const handleSelectAll = () => {
    setFilters(managers?.map((item) => item._id));
  };

  const handleClearAll = () => {
    setFilters([]);
  };

  useEffect(() => {
    handleFilter();
  }, [filtreText]);

  useEffect(() => {
    setFilteredItems(managers);
  }, [managers]);

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {' '}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => {
              setIsShownDropDown(false);
              setFilterText('');
            }}
          />
          <input
            type="text"
            autoComplete="off"
            placeholder="Filter"
            value={filtreText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button type="button" onClick={handleSelectAll}>
            Check all
          </button>
          <button type="button" onClick={handleClearAll}>
            Uncheck all
          </button>
          <div
            style={{
              marginTop: '5px',
              width: '100%',
              alignItems: 'start',
              maxHeight: '150px',
              overflow: 'auto',
            }}
          >
            {filteredItems?.map((item) => (
              <div
                style={{
                  margin: '5px',
                  fontSize: '12px',
                }}
              >
                <input
                  key={item?._id}
                  name={item?.username}
                  type="checkbox"
                  value={item?._id}
                  checked={filters.includes(item._id)}
                  onClick={(e) => handleClick(e)}
                />
                <label htmlFor={item?.username} style={{ marginLeft: '3px' }}>
                  {item?.firstName}
                  {' '}
                  {item?.lastName}
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : (
        null
      )}
    </div>
  );
}

export function RangeModalFilter({
  columnName, value, setValue, filters = {}, setFilters, filedName, maxValue = 1000000, 
}) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    if (filters) setFilters({ ...filters, [filedName]: newValue });
  };

  const setMin = (value) => {
    setValue((prev) => [Number(value), Number(prev[1])]);
    const prevValue = filters[filedName] ? filters[filedName][1] : 0;
    if (filters) setFilters({ ...filters, [filedName]: [Number(value), prevValue] });
  };

  const setMax = (value) => {
    setValue((prev) => [Number(prev[0]), Number(value)]);
    const prevValue = filters[filedName] ? filters[filedName][0] : maxValue;
    if (filters) setFilters({ ...filters, [filedName]: [prevValue, Number(value)] });
  };

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {' '}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          :
          <br />
          min
          <input type="number" value={value[0]} onChange={({ target: { value } }) => setMin(value)} />
          max
          <input type="number" value={value[1]} onChange={({ target: { value } }) => setMax(value)} />
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => setIsShownDropDown(false)}
          />
          <Slider value={value} onChange={handleSliderChange} max={maxValue} step={1} />
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export function DepositModalFilter({ columnName, setFilters, filters }) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => setIsShownDropDown(false)}
          />
          <FormControl
            variant="standard"
            style={{ m: 1, minWidth: '100%', backgroundColor: '#252A47' }}
          >
            <Select
              value={filters}
              onChange={(e) => setFilters(e.target.value)}
              style={{ minWidth: '100%' }}
            >
              <MenuItem>
                <em>All</em>
              </MenuItem>
              <MenuItem value>Real</MenuItem>
              <MenuItem value={false}>Fake</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        null
      )}
    </div>
  );
}

export function StatusModalFilter({ columnName, setFilters, filters }) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => setIsShownDropDown(false)}
          />
          <FormControl
            variant="standard"
            style={{ m: 1, minWidth: '100%', backgroundColor: '#252A47' }}
          >
            <Select
              value={filters}
              onChange={(e) => { setFilters(e.target.value); }}
              style={{ minWidth: '100%' }}
            >
              <MenuItem value={null}>
                <em>All</em>
              </MenuItem>
              <MenuItem value={1} sx={{ color: '#198754' }}>Completed</MenuItem>
              <MenuItem value={2} sx={{ color: '#F2214C  ' }}>Declined</MenuItem>
              <MenuItem value={0} sx={{ color: '#F6A20A' }}>Pending</MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        null
      )}
    </div>
  );
};

export function RangeModalDebounceFilter({
  columnName, value: data, setValue, filters = {}, setFilters, filedName, maxValue = 1000000, minValue = 0, debounceAmountRange = false,
}) {
  const [isShownDropDown, setIsShownDropDown] = useState(false);

  const handleSliderChange = (event, newValue) => {
    if (debounceAmountRange) {
      setValue(newValue);
      setFilters('slider', newValue, data, setValue);
      return;
    }   
    setValue(newValue);
    if (filters) setFilters({ ...filters, [filedName]: newValue });
  };

  const setMin = (value) => {
    if (debounceAmountRange) {
      setValue([Number(value), Number(data[1])]);
      setFilters('min', value, data, setValue);
      return;
    }
    setValue((prev) => [Number(value), Number(data[1])]);
    if (filters) setFilters({ ...filters, [filedName]: [Number(value), data[1]] });
  };

  const setMax = (value) => {
    if (debounceAmountRange) {
      setValue([Number(data[0]), Number(value)]);
      setFilters('max', value, data, setValue);
      return;
    }
    setValue((prev) => [Number(data[0]), Number(value)]);
    if (filters) setFilters({ ...filters, [filedName]: [data[0], Number(value)] });
  };

  return (
    <div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => (isShownDropDown ? setIsShownDropDown(false) : setIsShownDropDown(true))}
      >
        {columnName}
        {' '}
        {!isShownDropDown ? <ListIcon size="small" /> : <CloseIcon size="small" />}
      </div>
      {isShownDropDown ? (
        <div className="depositsDropdownModal">
          {columnName}
          :
          <br />
          min
          <input type="number" value={data[0]} onChange={({ target: { value } }) => setMin(value)} />
          max
          <input type="number" value={data[1]} onChange={({ target: { value } }) => setMax(value)} />
          <FontAwesomeIcon
            icon={faTimes}
            size="lg"
            color="#6E7F95"
            onClick={() => setIsShownDropDown(false)}
          />
          <Slider 
            value={data} 
            onChange={handleSliderChange} 
            max={maxValue} 
            min={minValue} 
            step={1} 
            valueLabelDisplay="auto"
          />
        </div>
      ) : (
        null
      )}
    </div>
  );
}
