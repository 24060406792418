import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { 
  setUserNameFilterCreator,
  setVerifyStatusFilterCreator,
  setTimeUploadedFilterCreator, 
} from '../redux/kycAml/kycAmlActions';
import { 
  selectUserNameSearchFilter,
  selectVerifyStatusSearchFilter,
  selectTimeUploadedFilter,
  selectKycStatuses, 
} from '../redux/kycAml/kycAmlSelectors';
import { ModalContext } from '../context';
import KycAmlRequests from '../pages/KycAml/KycAmlRequests/KycAmlRequests';
import { omitColumn, formatDate, dateOptions } from './helper';
import { CopyToClipboardCmp, ErrorSpan, ModalFilters } from '../components';

export const KycAmlColumns = ({ columnConfig, setKycQuery, kycQuery }) => {
  const dispatch = useDispatch();

  const { showModal } = useContext(ModalContext);
  
  // filters variables
  const nameSearchFilter = useSelector(selectUserNameSearchFilter);
  const timeUploadedFilter = useSelector(selectTimeUploadedFilter);
  const verifyStatusSearchFilter = useSelector(selectVerifyStatusSearchFilter);
  const verifiedStatuses = useSelector(selectKycStatuses);

  const openVerifyRequestsPage = (userId) => {
    showModal({
      headerContent: <h3>KYC/AML Requests</h3>,
      bodyContent: <KycAmlRequests userId={userId} />,
    });
  };

  const columns = [
    {
      name: 'ID',
      minWidth: '150px',
      cell: ({ customId }) => <CopyToClipboardCmp label={`${customId}`} />,
      omit: omitColumn(columnConfig, 'ID'), 
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Client
          <input
            type="text"
            name="name"
            autoComplete="off"
            value={nameSearchFilter || ''}
            placeholder="Filter by user name"
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setUserNameFilterCreator(value))}
          />
          <ErrorSpan filter={nameSearchFilter} message="Please enter atleast 3 charcater" />
        </div>
      ),
      width: '250px',
      cell: ({ userInfo: { firstName, lastName }, userId }) => (
        <Link to={`/user-detail/${userId}`} target="blank" className="text-white">
          <FontAwesomeIcon icon={faExternalLink} size="xs" />
          <span 
            className="cfdSccounts-row_fullName"
          >
            {`${firstName} ${lastName}`}
          </span>
        </Link>
      ),
      omit: omitColumn(columnConfig, 'Client'), 
    },
    {
      name: (
        <ModalFilters 
          data={verifiedStatuses}
          filters={verifyStatusSearchFilter}
          setFilters={(value) => dispatch(setVerifyStatusFilterCreator(value))}
          tabName="Status" 
          searchParams={{ id: 'value', params: ['name'], optionsName: ['name'] }}
          usersFilters={kycQuery}
          setUserFilters={setKycQuery}
        />
      ),
      width: '250px',
      cell: ({ verifiedStatus }) => verifiedStatus,
      omit: omitColumn(columnConfig, 'Type'), 
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeUploadedFilter} 
          setFilters={(value) => dispatch(setTimeUploadedFilterCreator(value))} 
          tabName="Time Uploaded" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={kycQuery}
          setUserFilters={setKycQuery}
          isDateOptions
        />
      ),
      cell: ({ createdAt }) => formatDate(new Date(createdAt)),
      omit: omitColumn(columnConfig, 'Time Uploaded'), 
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Time Processed
        </div>
      ),
      cell: ({ updatedAt }) => formatDate(new Date(updatedAt)),
      omit: omitColumn(columnConfig, 'Time Processed'),
      selector: (row) => row.updatedAt,
      sortable: true,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Edit Action
        </div>
      ),
      cell: ({ userId }) => (
        <button 
          type="button" 
          className="btn btn-success btn-sm me-1 p-1"
          onClick={() => openVerifyRequestsPage(userId)}
        >
          <FontAwesomeIcon icon={faPencil} size="sm" style={{ marginRight: '5px' }} />
          Edit
        </button>
      ),
      omit: omitColumn(columnConfig, 'Actions'), 
    },
  ];

  return columns;
};
