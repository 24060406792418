import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getClientLogins, exportClientLoginToFile } from '../../redux/clientLogin/clientLoginActions';
import { ClientLoginActivityColumns } from '../../DataTableSchemas/ClientLoginActivityColumns';
import { useDebounce } from '../../hooks/useDebounce';

function ClientLoginActivity() {
  const dispatch = useDispatch();
  const paginationStorageName = 'DataTable_activity/client-login_pagination';

  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const [userFilters, setUserFilters] = useState({});
  const [clientNameFilter, setClientNameFilter] = useState(null);
  const [clientIP, setClientIP] = useState(null);
  const [clientBrowser, setClientBrowser] = useState(null);

  const allClientLogins = useSelector((state) => state?.clientLogin?.clientLogins);
      
  const setStoredPagination = () => {
    const clientLoginActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (clientLoginActivityRowsJSON) {
      const filterRows = JSON.parse(clientLoginActivityRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }
    
    setIsPaginationDT(true);
  };

  const getActivities = (page, rowsPerPage, userFilters) => dispatch(getClientLogins({ page, rowsPerPage, userFilters }));
  
  const handlePageChange = (page) => setPage(page);

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };
  
  const handleExportClientLoginLogs = async (fileType) => {
    await dispatch(exportClientLoginToFile({
      page, 
      rowsPerPage, 
      filters: userFilters,
      fileType, 
    }));
  };

  useDebounce(clientNameFilter, 1000, (value) => {
    if (value.length >= 3 || value.length === 0) {
      setUserFilters({ ...userFilters, clientName: value });
    }
  });

  useDebounce(clientIP, 1000, (value) => {
    if (value.length >= 3 || value.length === 0) {
      setUserFilters({ ...userFilters, ip: value });
    }
  });

  useDebounce(clientBrowser, 1000, (value) => {
    if (value.length >= 3 || value.length === 0) {
      setUserFilters({ ...userFilters, browser: value });
    }
  });

  const loginColumns = ClientLoginActivityColumns(
    clientNameFilter, 
    setClientNameFilter,
    clientIP, 
    setClientIP,
    clientBrowser, 
    setClientBrowser,
  );

  useEffect(() => {
    setStoredPagination();
  }, []);

  useEffect(async () => {
    if (isPaginationDT) {
      setTableLoading(true);
      await getActivities(page, rowsPerPage, userFilters);
      setTableLoading(false);
    }
  }, [isPaginationDT, userFilters, page, rowsPerPage]);

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Clients Logins</h3>
        <div className="d-flex">
          <Dropdown className="leads-download-dropdown ms-1">
            <Dropdown.Toggle variant="" className="btn-default" id="dropdown-basic">
              Download
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="import-csv-btn text-center mb-1">
                <button type="button" className="btn btn-default" onClick={() => handleExportClientLoginLogs('xlsx')}>Excel</button>
              </div>
              <div className="import-csv-btn text-center">
                <button type="button" className="btn btn-default" onClick={() => handleExportClientLoginLogs('csv')}>CSV</button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {isPaginationDT
                  && (
                    <DataTable
                      columns={loginColumns}
                      data={allClientLogins.paginatedResult}
                      pagination
                      paginationServer
                      highlightOnHover
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 50, 100, 500]}
                      paginationTotalRows={allClientLogins.total}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onChangePage={handlePageChange}
                      persistTableHead
                      selectableRowsHighlight
                      selectableRowsVisibleOnly
                      theme="solarizedd"
                      className="specific-field-table custome-table-scroll"
                      progressPending={tableLoading}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )}
        </div>
      </div>
    </div>
  );
}

export default ClientLoginActivity;
