import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import * as Flags from 'react-flags-select';
import { BsTrashFill } from 'react-icons/bs';
import currencyFormatter from 'currency-formatter';
import { ModalFilters } from '../components';
import { nonNegativeAmount } from '../helpers/helper';

export const AffiliateClientColumns = (
  fullname,
  searchByName,
  searchEmail,
  searchByEmail,
  permissionName,
  copyReferral,
  handleCountryDropdownShow,
  countryDropdownShow,
  countryInput,
  myCountryHandler,
  handleSelectAllCountries,
  countriesToShow,
  isCheckCountry,
  handleCountryClick,
  titleCase,
  handleStatusDropdownShow,
  statusDropdownShow,
  statusInput,
  myStatustHandler,
  handleSelectAllStatuses,
  statusesToShow,
  isCheckStatus,
  handleStatusClick,
  salesStatuses,
  handleStatusChange2,
  handleAssignToDropdownShow,
  assignToDropdownShow,
  assignToInput,
  assignToHandler,
  handleSelectAllAssignTo,
  assignToShow,
  isCheckAssignTo,
  handleAssignToClick,
  formatDate,
  timePassed,
  deleteAction,
  userAction,
  filters,
  setFilters,
  setIsCheckCountry,
  setIsCheckStatus,
  setIsCheckAssignTo,
) => {
  const clientColumns = [
    // {
    //    name: 'UID',
    //    selector: row => {
    //       return (
    //          <>
    //             <CopyToClipboard text={row?.users._id}>
    //                <span>
    //                   {row?.users._id?.slice(0, 4)}...{row?.users._id?.slice(row?.users._id.length - 4, row?.users._id.length)}
    //                   <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
    //                </span>
    //             </CopyToClipboard>
    //          </>
    //       )
    //    }
    // },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Full Name
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={fullname}
            minLength="3"
            onChange={(e) => searchByName(e)}
          />
          {
            (fullname.length >= 1 && fullname.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter atleast 3 charcater</span>
            )  
          }
        </div>
      ),
      width: '250px',
      selector: (row) => `${row.firstName} ${row.lastName}`,
      cell: (row) => (
        <>
          <Link to={`/user-detail/${row._id}`}>
            <span
              data-tip={`${row.firstName} ${row.lastName}`}
              className="full-name-text"
            >
              {row.firstName}
              {' '}
              {row.lastName}
            </span>
          </Link>
          {/* <ReactTooltip /> */}
        </>
      ),
      sortable: false,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          Email
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            name="clientFullname"
            autoComplete="off"
            value={searchEmail}
            onChange={(e) => searchByEmail(e)}
          />
          {
            (searchEmail.length >= 1 && searchEmail.length < 3) && (
            <span className="datatable-input__error-text clr-red">Please enter atleast 3 charcater</span>
            )  
          }
        </div>
      ),
      width: '250px',
      selector: (row) => (
        permissionName
          && permissionName.length > 0
          && permissionName.includes('user_email') ? (
            <CopyToClipboard text={row.email}>
              <span style={{ margin: '5px' }} data-tip={row.email}>
                {row.email}
                <FontAwesomeIcon
                  icon={faClone}
                  onClick={() => copyReferral()}
                  className="ms-2 cursor-pointer"
                />
              </span>
            </CopyToClipboard>
          ) : null
      ),
      sortable: false,
    },
    {
      name: 'Phone Number',
      width: '200px',
      selector: (row) => (
        permissionName
          && permissionName.length > 0
          && permissionName.includes('user_phone') ? (
            <CopyToClipboard text={row.phone}>
              <span style={{ margin: '5px' }} data-tip={row.phone}>
                <FontAwesomeIcon
                  icon={faClone}
                  onClick={() => copyReferral()}
                  className="me-2"
                />
                {row.phone}
              </span>
            </CopyToClipboard>
          ) : null
      ),
    },
    {
      name: (
        <ModalFilters
          data={countriesToShow} 
          filters={isCheckCountry} 
          setFilters={setIsCheckCountry} 
          tabName="Country" 
          searchParams={{ id: 'iso', params: ['nicename', 'iso', 'iso3', 'name'], optionsName: ['nicename'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      //   <div>
      //     Country
      //     <div className="">
      //       <div
      //         className="dropdown search-custom-dropdown dropdown-check-nostyle crm-search-dropdown"
      //         style={{ position: 'unset' }}
      //       >
      //         <button
      //           type="button"
      //           onClick={handleCountryDropdownShow}
      //           className="dropbtn text-start"
      //           id="country-dropdown-show"
      //         >
      //           Search
      //         </button>
      //         <div
      //           id="myDropdown"
      //           className={`dropdown-content ${
      //             countryDropdownShow ? 'show' : ''
      //           }`}
      //         >
      //           <input
      //             type="text"
      //             placeholder="Filter"
      //             value={countryInput}
      //             id="myInput"
      //             onChange={myCountryHandler}
      //           />
      //           <div className="btn-group">
      //             <button
      //               type="button"
      //               className="actions-btn"
      //               onClick={(e) => handleSelectAllCountries(e, true)}
      //             >
      //               Check all
      //             </button>
      //             <button
      //               type="button"
      //               className="actions-btn"
      //               onClick={(e) => handleSelectAllCountries(e, false)}
      //             >
      //               Uncheck all
      //             </button>
      //           </div>
      //           <div className="search-options">
      //             {countriesToShow?.length > 0
      //               ? countriesToShow?.map((country) => (
      //                 <>
      //                   <input
      //                     className="styled-checkbox"
      //                     id={`styled-checkbox-${country?._id}`}
      //                     type="checkbox"
      //                     value={country?._id}
      //                     checked={
      //                         isCheckCountry?.includes(country?._id) || false
      //                       }
      //                     onChange={(e) => handleCountryClick(e)}
      //                   />
      //                   <label htmlFor={`styled-checkbox-${country?._id}`}>
      //                     {country?.nicename}
      //                   </label>
      //                 </>
      //               ))
      //               : null}
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // ),
      width: '200px',
      selector: (row) => {
        if (row.countryCode && row.country?.[0]?.nicename) {
          const flagTitleCase = titleCase(row.countryCode);
          const UserCountryFlag = Flags[flagTitleCase];

          return (
            <>
              <span style={{ fontSize: 18 }}>
                { UserCountryFlag && <UserCountryFlag /> }
              </span>
              {' '}
              <span data-tip={row.country?.[0]?.nicename}>
                {row.country?.[0]?.nicename}
              </span>
              {/* <ReactTooltip /> */}
            </>
          );
        }
        return '-';
      },
    },
    {
      name: (
        <ModalFilters 
          data={salesStatuses} 
          filters={isCheckStatus} 
          setFilters={setIsCheckStatus} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      minWidth: '200px',
      selector: (row) => (row.salesStatusId ? row.salesStatusId : ''),
      cell: (row) => {
        let currSalesStatusType = {
          _id: '',
          name: 'Select Status',
          color: '#fff',
        };
        if (row.salesStatusId) {
          currSalesStatusType = salesStatuses.find(
            (stat) => stat._id === row.salesStatusId,
          );
        }
        return (
          <select
            className="form-control user-status-select leads-status-select"
            name="type"
            value={currSalesStatusType?._id}
            onChange={(e) => handleStatusChange2(row._id, e)}
            style={{
              color: currSalesStatusType?.color
                ? currSalesStatusType?.color
                : '#fff',
            }}
          >
            <option value="" style={{ color: '#fff' }} color="#fff">
              Select Status
            </option>
            {salesStatuses?.length > 0
              && salesStatuses?.map((currentStatus) => (
                <option
                  value={currentStatus?._id}
                  key={currentStatus?._id}
                  style={{ color: currentStatus?.color }}
                  color={currentStatus?.color}
                >
                  {' '}
                  {currentStatus?.name}
                </option>
              ))}
          </select>
        );
      },
      // selector: row => {
      //    if (row?.users?.clientStatus)
      //       return `${userTypeStatus?.find(stat => stat.value == row?.users?.clientStatus)?.label}`
      //    else
      //       return userTypeStatus?.[0]?.label
      // },
      // cell: (row) => {
      //    let currStatus = userTypeStatus?.[0];
      //    if (row?.users?.clientStatus) {
      //       currStatus = userTypeStatus.find(stat => stat.value == row?.users?.clientStatus);
      //    }
      //    return (
      //       <>
      //          <Select
      //             value={currStatus}
      //             onChange={(selectedUserTypeStatus) => handleStatusChange(row?.users._id, selectedUserTypeStatus)}
      //             options={userTypeStatus}
      //             styles={colourStyles}
      //          />
      //       </>
      //    );
      // }
    },
    {
      name: 'Balance',
      selector: (row) => nonNegativeAmount(row.account?.previousTotalAmount),
      cell: (row) => (
        <span>
          {currencyFormatter.format(nonNegativeAmount(row.account?.previousTotalAmount), {
            code: 'USD',
          })}
        </span>
      ),
      sortable: true,
    },
    {
      name: 'Affiliator',
      selector: (row) => {
        if (row.affiliator) { return `${row.affiliator?.[0]?.firstName} ${row.affiliator?.[0]?.lastName}`; }
        return '-';
      },
      cell: (row) => {
        const affiliator = row.affiliator?.[0]
          ? row.affiliator?.[0]
          : '';
        return (
          affiliator ? (
            <>
              <span
                data-tip={`${affiliator?.firstName} ${affiliator?.lastName}`}
              >
                {affiliator?.firstName}
                {' '}
                {affiliator?.lastName}
              </span>
              {/* <ReactTooltip /> */}
            </>
          ) : (
            '-'
          )
        );
      },
      omit:
        !permissionName
        || permissionName.length < 1
        || !permissionName.includes('assign_to_agent'),
    },
    {
      name: (
        <ModalFilters 
          data={assignToShow} 
          filters={isCheckAssignTo} 
          setFilters={setIsCheckAssignTo} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      width: '250px',
      selector: (row) => {
        if (row.assignedToAgent) { return `${row.assignedToAgent?.[0]?.firstName} ${row.assignedToAgent?.[0]?.lastName}`; }
        return '-';
      },
      cell: (row) => {
        const assignedToAgent = row.assignedToAgent?.[0]
          ? row.assignedToAgent?.[0]
          : '';
        return (
          assignedToAgent ? (
            <>
              <span
                data-tip={`${assignedToAgent?.firstName} ${assignedToAgent?.lastName}`}
              >
                {assignedToAgent?.firstName}
                {' '}
                {assignedToAgent?.lastName}
              </span>
              {/* <ReactTooltip /> */}
            </>
          ) : (
            '-'
          )
        );
      },
      omit:
        !permissionName
        || permissionName.length < 1
        || !permissionName.includes('assign_to_agent'),
    },
    {
      name: 'Registration Date',
      selector: (row) => (
        <>
          <span data-tip={formatDate(new Date(row.createdAt))}>
            {formatDate(new Date(row.createdAt))}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
    },
    {
      name: 'Last Login Date',
      selector: (row) => (
        <>
          <span
            data-tip={
              row.lastLoginAt
                ? formatDate(new Date(row.lastLoginAt))
                : '-'
            }
          >
            {row.lastLoginAt
              ? formatDate(new Date(row.lastLoginAt))
              : '-'}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
    },
    {
      name: 'Online',
      selector: (row) => (
        <>
          <span
            data-tip={
              timePassed(row.lastLoginAt)
              && row.isLogin === true
                ? timePassed(row.lastLoginAt)
                : 'Offline'
            }
          >
            {timePassed(row.lastLoginAt) && row.isLogin === true
              ? timePassed(row.lastLoginAt)
              : 'Offline'}
          </span>
          {/* <ReactTooltip /> */}
        </>
      ),
      sortable: true,
    },
    {
      name: 'Delete User',
      width: '300px',
      cell: (row) => (
        <>
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('delete_user') ? (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAction(row._id)}
            >
              <BsTrashFill color="white" size={18} />
            </button>
            ) : null}
          {/* {row.refCount >= 0 && row.refCount < 2 ?
                         <button className="btn btn-warning btn-sm me-1 p-1" onClick={() => viewReferrals(row?.users?._id, setShow(false))}>Referrals</button>
                         : null
                      } */}
          {permissionName
          && permissionName.length > 0
          && permissionName.includes('block_user') ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {row.status === true ? (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row._id, 'block')}
                >
                  Block
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-warning btn-sm me-1 p-1"
                  onClick={() => userAction(row._id, 'unBlock')}
                >
                  Unblock
                </button>
              )}
            </div>
            ) : null}
        </>
      ),
    },
  ];
  return clientColumns;
};
