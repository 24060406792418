import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { ModalContext } from '../../context';
import { CfdSettingsSchema } from '../../DataTableSchemas/CfdSettingsSchema';
import { CfdSettingsModal, MassEditCFDSettingsModal } from './modals';
import { getCfdMarketTypes } from '../../redux/cfdMarketTypes/cfdMarketTypesActions';

export function CfdSettings() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const [tableLoading, setTableLoading] = useState(true);
  const [selectedSettingsRows, setSelectedSettingsRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const cfdSettings = useSelector((state) => state.cfdSettings);

  useEffect(async () => {
    await Promise.allSettled([
      dispatch(getCfdSettings()),
      dispatch(getCfdMarketTypes()),
    ]);
    setTableLoading(false);
  }, []);

  const columns = CfdSettingsSchema(showModal, CfdSettingsModal);

  const handleSettingsTableSelect = async ({ selectedRows }) => {
    setSelectedSettingsRows(selectedRows);
  };

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedSettingsRows([]);
  };

  const handleEditMassSettingTable = () => {
    showModal({
      headerContent: <h3>Mass Edit Settings</h3>,
      bodyContent: <MassEditCFDSettingsModal settings={selectedSettingsRows} hideModal={hideModal} clearRows={clearRows} />,
    });
  };

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h4 className="mb-3 text-capilatize">CFD Settings</h4>
        <div className="action__btn-row mb-3">
          <div style={{
            display: 'flex', alignItems: 'center', color: '#fff', 
          }}
          >
            <div>{`Selected ${selectedSettingsRows.length} records`}</div>
            <button 
              disabled={!selectedSettingsRows.length}
              type="button"
              className="btn btn-default ms-1"
              onClick={handleEditMassSettingTable}
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="sm" />
              Mass Edit Settings
            </button>
          </div>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
          <div className="table-responsive">
            <DataTable
              data={cfdSettings}
              columns={columns}
              pagination
              paginationRowsPerPageOptions={[25, 50, 200, 500]}
              onSelectedRowsChange={handleSettingsTableSelect}
              selectableRows
              persistTableHead
              theme="solarizedd"
              progressPending={tableLoading}
              clearSelectedRows={toggleCleared}
              progressComponent={<div className="datatable-loader__background" />}
            />
          </div>
        </div>
      </div>
    </div>
          
  );
}
