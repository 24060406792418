import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { ModalContext } from '../context';
import {
  deleteSmtpPreset, setSmtpAgentFilterCreator, setSmtpDescriptionFilterCreator, setSmtpEmailFilterCreator, setSmtpPasswordFilterCreator, setSmtpProviderFilterCreator, 
} from '../redux/smtpPresets/smtpPresetsActions';
import { EditSmtpPreset } from '../pages/SmtpPresets/EditSmtpPreset/EditSmtpPreset';
import {
  selectSmtpAgentSearchFilter, selectSmtpDescriptionSearchFilter, selectSmtpEmailSearchFilter, selectSmtpPasswordSearchFilter, selectSmtpProviderSearchFilter, 
} from '../redux/smtpPresets/smtpPresetsSelectors';
import { getCRMUsers } from '../redux/crmUser/crmUserActions';

export const SmtpPresetsColumns = () => {
  const dispatch = useDispatch();

  const { showModal, hideModal } = useContext(ModalContext);

  const crmUsers = useSelector((state) => state.crmUser.crmUsers);
  const permissions = useSelector(
    (state) => state.crmUser?.currentUserPermissions,
  );

  const isUserHasEditTemplatePermission = permissions && permissions.length > 0 && permissions.includes('edit_smtp_presets'); 

  // filters variables
  const agentSearchFilter = useSelector(selectSmtpAgentSearchFilter);
  const providerSearchFilter = useSelector(selectSmtpProviderSearchFilter);
  const emailSearchFilter = useSelector(selectSmtpEmailSearchFilter);
  const passwordSearchFilter = useSelector(selectSmtpPasswordSearchFilter);
  const descriptionSearchFilter = useSelector(selectSmtpDescriptionSearchFilter);

  useEffect(() => {
    if (crmUsers && crmUsers.length === 0) {
      dispatch(getCRMUsers());
    }
  }, [crmUsers]);

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSmtpPreset(id));
      }
    });
  };

  const editAction = (row) => {
    showModal({
      headerContent: <h3>Edit Email Template</h3>,
      bodyContent: <EditSmtpPreset preset={row} hideModal={hideModal} />,
    });
  };

  const columns = [
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Agent
          <select
            name="agent"
            value={agentSearchFilter}
            className="email-templates-search-bar-filter"
            onChange={({ target: { value } }) => dispatch(setSmtpAgentFilterCreator(value))}
          >
            {[{ _id: '', firstName: 'none', lastName: '' }, ...crmUsers].map((user) => (<option value={user._id}>{`${user.firstName} ${user.lastName}`}</option>))}
          </select>
        </div>
      ),
      cell: ({ agentName }) => (<span>{agentName}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Provider
          <input
            type="text"
            name="provider"
            autoComplete="off"
            value={providerSearchFilter}
            placeholder="Filter by provider"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpProviderFilterCreator(e.target.value))}
          />
        </div>
      ),
      selector: ({ provider }) => (<span>{provider}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Email
          <input
            type="text"
            name="email"
            autoComplete="off"
            value={emailSearchFilter}
            placeholder="Filter by email"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpEmailFilterCreator(e.target.value))}
          />
        </div>
      ),
      width: '250px',
      selector: ({ email }) => `${email}`,
      cell: ({ email }) => (<span>{email}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Password
          <input
            type="text"
            name="password"
            autoComplete="off"
            value={passwordSearchFilter}
            placeholder="Filter by password"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpPasswordFilterCreator(e.target.value))}
          />
        </div>
      ),
      width: '250px',
      selector: ({ password }) => `${password}`,
      cell: ({ password }) => (<span>{password}</span>),
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-4 pl-0" style={{ alignItems: 'start' }}>
          Description
          <input
            type="text"
            name="description"
            autoComplete="off"
            value={descriptionSearchFilter}
            placeholder="Filter by description"
            className="email-templates-search-bar-filter"
            onChange={(e) => dispatch(setSmtpDescriptionFilterCreator(e.target.value))}
          />
        </div>
      ),
      width: '250px',
      selector: ({ description }) => `${description}`,
      cell: ({ description }) => (<span>{description}</span>),
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          {isUserHasEditTemplatePermission ? (
            <button
              className="btn btn-warning btn-sm me-1 p-1"
              type="button"
              onClick={() => editAction(row)}
            >
              Edit
            </button>
          ) : null}
          <button
            type="button"
            className="btn btn-danger btn-sm me-1 p-1"
            onClick={() => deleteAction(row._id)}
          >
            <BsTrashFill size={18} />
          </button>
        </>
      ),
    },
  ];

  return columns;
};
