import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencil,
  faPlusCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import {
  getOptions,
  getSavings,
  deleteSaving,
} from '../../redux/saving/savingActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';

function Saving() {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterCurrency, setFilterCurrency] = useState(null);
  const [filterDeposit, setFilterDeposit] = useState(null);

  const savings = useSelector((state) => state.saving.savings);
  const savingOptions = useSelector((state) => state.saving.options);

  useEffect(async () => {
    setLoader(true);
    Promise.allSettled([dispatch(getOptions()), dispatch(getSavings())]);
    setLoader(false);
  }, []);

  const deleteAction = async (savingId) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteSaving(savingId));
      }
    });
  };

  useEffect(() => {
    const searchResult = savings.filter((item) => {
      const matchesCurrency = filterCurrency && filterCurrency.trim() 
        ? (
          item.currency.name.toLowerCase().includes(filterCurrency.trim().toLowerCase())
            || item.currency.symbol.toLowerCase().includes(filterCurrency.trim().toLowerCase())
        )
        : true;
      const matchesDeposit = filterDeposit && filterDeposit.trim() 
        ? item.minDeposit.toString().toLowerCase().includes(filterDeposit.trim().toLowerCase()) 
        : true;
  
      return matchesCurrency && matchesDeposit;
    });

    setFilteredItems(searchResult);
  }, [filterCurrency, filterDeposit, savings]);

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h4 className="mb-3 text-capilatize">Saving</h4>
          <div className="action-btns mb-5">
            <Link to="/add-saving">
              <button type="button" className="btn btn-default">
                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                Add
              </button>
            </Link>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper staking-tbl">
            <div className="table-responsive">
              <table>
                <tr>
                  <th>Currency</th>
                  <th>Minimum Deposit</th>
                  {savingOptions.map((option) => (
                    <th key={option._id}>{option.isFlexible ? option.label : `Locked ${option.label}`}</th>
                  ))}
                  <th>Actions</th>
                </tr>
                <tr className="search-holder">
                  <th>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        name="filterCurrency"
                        value={filterCurrency}
                        onChange={({ target: { value } }) => setFilterCurrency(value)}
                      />
                    </div>
                  </th>
                  <th>
                    <div className="input-group">
                      <input
                        type="text"
                        placeholder="Search"
                        name="filterDeposit"
                        value={filterDeposit}
                        onChange={({ target: { value } }) => setFilterDeposit(value)}
                      />
                    </div>
                  </th>
                  <th colSpan={100} />
                </tr>
                {filteredItems.length ? (
                  filteredItems.map((item) => (
                    <tr key={`saving-${item?._id}`}>
                      <td>{`${item?.currency.name} (${item?.currency.symbol})`}</td>
                      <td>{item?.minDeposit}</td>
                      {item.options?.map(({ _id, percent }) => (
                        <td key={`${item?.currency.name}-${_id}`}>{`${percent} %`}</td>
                      ))}
                      <td>
                        <div className="action-btn">
                          <Link
                            className="btn-complete"
                            to={`/edit-saving/${item?._id}`}
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                          <button
                            type="button"
                            className="btn-cancel"
                            onClick={() => deleteAction(item?._id)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={100}>No Record Found</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default Saving;
