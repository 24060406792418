import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import './style.css';

export function DatatableCRMActions({ 
  ids, 
  templates, 
  editCRMUsers,
}) {
  const defaultDropdownName = 'Select';
  const [dropdownName, setDropdownName] = useState('Actions');
  const [templateDropdownName, setTemplateDropdownName] = useState(defaultDropdownName);
  const [isChangeTemplate, setIsChangeTemplate] = useState(false);
  const [template, setTemplate] = useState({});

  // filtered data
  const [searchValue, setSearchValue] = useState('');
  const filteredTemplates = useMemo(() => {
    const value = searchValue.toLowerCase();
    return templates.filter((temp) => (
      temp.name.toLowerCase().includes(value) || temp._id.toLowerCase().includes(value)
    ));
  }, [templates, searchValue]);

  const showCmp = (name, event) => {
    event(true);
    setDropdownName(name);
  };

  const selectTemplate = (template) => {
    setTemplateDropdownName(template.name);
    setTemplate(template);
  };

  const handleChangeTemplate = () => {
    if (!Object.keys(template).length) {
      toast.error('Select rights template name to complete this action', {
        autoClose: 1000,
      });

      return;
    }

    const data = {
      ids,
      permissionIds: template.permissions,
    };

    editCRMUsers(data, 'Are you sure you want to set rights for this CRM user(s)?')
      .then((res) => {
        if (res) {
          setTemplate({});
          setTemplateDropdownName(defaultDropdownName);
        }
      });
  };

  return (
    <div className="dt-actions__container">
      <span className="dt-actions__selected-counter">{`Selected ${ids.length} records`}</span>
      <Dropdown className="dt-actions__dropdown ms-1">
        <Dropdown.Toggle variant="" className="btn-default">{dropdownName}</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => showCmp('Set Rights', setIsChangeTemplate)}>Set Rights</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {
                isChangeTemplate && (
                <>
                  <Dropdown className="dt-actions__dropdown ms-1">
                    <Dropdown.Toggle variant="" className="btn-default">{templateDropdownName}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      <input className="leads-search-bar-filter" autoComplete="off" type="text" placeholder="Search" onChange={({ target }) => setSearchValue(target.value)} />
                      {filteredTemplates.map((template) => (
                        <Dropdown.Item key={template._id} onClick={() => selectTemplate(template)}>{template.name}</Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <button className="btn-default ms-1 bg-green" type="button" onClick={handleChangeTemplate}>Apply</button>
                </>
                )
            }
    </div>
  );
};
