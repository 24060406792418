import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

serviceWorkerRegistration.register();
