/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import ReactTooltip from 'react-tooltip';
import { getSubCRMUsers } from '../../../redux/crmUser/crmUserActions';
import { getCRMUserActivity } from '../../../redux/crmActivity/crmActivityActions';
import { getActivityTypes } from '../../../redux/activityType/activityTypeActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
import { ModalFilters } from '../../../components';
import { useDebounce } from '../../../hooks/useDebounce';
import { formatDate } from '../../../DataTableSchemas/helper';

import styles from './styles.module.css';


export function CRMUSerActivity({ uid }) {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [crmUserActivityFilter, setCrmUserActivityFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [crmUserFilter, setCrmUserFilter] = useState({});
  const [, setAgentUsers] = useState([]);
  const [, setAssignedTo] = useState(null);
  const [, setAssignToShow] = useState([]);
  const [clientFullname, setClientFullname] = useState();
  const [inputFieldError, setInputFieldError] = useState({
    fullNameIsNotValid: false,
    errorMessage: '',
  });

  const paginationStorageName = 'DataTable_crmUserActivity/pagination';
  const filtersStorageName = 'DataTable_crmUserActivity/filters'; 
  const crmUserFiltersJSON = localStorage.getItem(filtersStorageName);

  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const activityTypes = useSelector((state) => state?.activityType?.activityTypes);
  const { paginatedData: crmUserActivityData, totalCount } = useSelector((state) => state?.crmActivity?.crmUserActivity);

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && crmUserFilter[key] !== value) {
      setCrmUserFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(clientFullname, 1000, (value) => debounceCallback({ value, key: 'fullName' }));

  const setStoredPagination = () => {
    const depositRowsJSON = localStorage.getItem(paginationStorageName);
    if (depositRowsJSON) {
      const filterRows = JSON.parse(depositRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    if (crmUserFiltersJSON) {
      const filters = JSON.parse(crmUserFiltersJSON);
      setCrmUserFilter(filters || {});

      setClientFullname(filters.fullName || null);
      setCrmUserActivityFilter(filters.activity || []);
    };
  };

  const getAllStoredData = () => {
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    getAllStoredData();
    const fetchData = async () => {
      const loginData = localStorage.getItem('user');
      const data = JSON.parse(loginData);
      const roleId = data?.roleId;
      const userId = data?._id;

      setLoader(true);  
      await dispatch(getSubCRMUsers(roleId, userId));
      await dispatch(getActivityTypes());
      setLoader(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (crmUsers?.length > 0) {
      const allAgents = crmUsers?.map((agen) => ({ label: `${agen.firstName} ${agen.lastName}`, value: agen._id }));
      setAgentUsers(allAgents);
      setAssignToShow(crmUsers);
      setAssignedTo({ label: allAgents?.[0]?.label, value: allAgents?.[0]?.value });
    }
  }, [crmUsers]);

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  useEffect(() => {
    async function fetchdata() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify(crmUserFilter));
        setTableLoading(true);
  
        await dispatch(getCRMUserActivity({
          page, limit: rowsPerPage, id: uid, query: crmUserFilter, 
        }));
  
        setTableLoading(false);
      }
    };

    fetchdata();
  }, [page, rowsPerPage, isPaginationDT, uid, crmUserFilter]);

  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'fullNameIsNotValid') setClientFullname(value);
  };

  const crmUserActivityCol = [
    {
      name: 'Time',
      selector: ({ createdAt }) => (
        <>
          <span data-tip={formatDate(new Date(createdAt))}>
            {formatDate(new Date(createdAt))}
          </span>
          <ReactTooltip />
        </>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client
          <input
            type="text"
            placeholder="Search"
            className="leads-search-bar-filter"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            name="clientFullname"
            value={clientFullname ?? ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.fullNameIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      cell: ({ userId, client }) => {
        if (userId) {
          return permissionName
            && permissionName.length > 0
            && permissionName.includes('edit_user') ? (
              <span
                data-tip={`${client?.firstName} ${client?.lastName}`}
                className="full-name-text"
              >
                {client?.firstName}
                {' '}
                {client?.lastName}
              </span>
            ) : (
              <span>{`${client?.firstName} ${client?.lastName}`}</span>
            );
        }
        return '-';
      },
    },
    {
      name: 
      (<ModalFilters 
        data={activityTypes} 
        filters={crmUserActivityFilter} 
        setFilters={setCrmUserActivityFilter} 
        tabName="Activity" 
        searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        usersFilters={crmUserFilter}
        setUserFilters={setCrmUserFilter}
      />),
      minWidth: '100px',
      cell: (row) => <span>{row?.activityType?.name}</span>,
    },
    {
      name: 'Information',
      cell: ({ information }) => <span>{information}</span>,
    },
  ];

  return (
    loader ? (<FullPageTransparentLoader />)
      : (
        <div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            { 
              isPaginationDT && (
              <DataTable
                title="Activity Log"
                columns={crmUserActivityCol}
                data={crmUserActivityData}
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={rowsPerPage}
                highlightOnHover
                paginationRowsPerPageOptions={[25, 50, 100, 500]}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                paginationDefaultPage={page}
                persistTableHead
                selectableRowsHighlight
                selectableRowsVisibleOnly
                theme="solarizedd"
                progressPending={tableLoading}
                progressComponent={<div className="datatable-loader__background" />}
              />
              )
            }
          </div>
        </div>
      )
  );
}
