/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '../hooks/useDebounce';
import { ModalFilters } from '../components';
import { getCfdSettingsByQuery } from '../redux/cfdSettings/cfdActions';

export const CfdSettingsSchema = (showModal, CfdSettingsModal) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const [searchByAlias, setSearchByAlias] = useState(null);
  const [marketTypesFilter, setMarketTypesFilter] = useState([]);  
  const [cfdSettingsFilter, setCfdSettingsFilter] = useState([]);

  const cfdMarketTypes = useSelector((state) => state.cfdMarketTypes);

  useDebounce(searchByAlias, 300, () => dispatch(getCfdSettingsByQuery(searchByAlias, marketTypesFilter)));

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      dispatch(getCfdSettingsByQuery(searchByAlias, marketTypesFilter));
    }
  }, [marketTypesFilter]);
  
  const columns = [
    {
      name: 'Symbol',
      cell: (row) => row.primary,
    },
    {
      name: (
        <div className="d-flex flex-column search-input-des p-2 pl-0" style={{ alignItems: 'start' }}>
          <span className="d-flex ">Alias</span>
          <input
            className="leads-search-bar-filter"
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="clientFullname"
            value={searchByAlias}
            minLength="3"
            onChange={(e) => setSearchByAlias(e.target.value)}
          />
        </div>
      ),
      cell: (row) => row.alias,
      width: '300px',
    },
    {
      name: 'Base Currency',
      cell: (row) => row.secondary,
      width: '140px',
    },
    {
      name: (
        <ModalFilters 
          data={cfdMarketTypes}
          filters={marketTypesFilter} 
          setFilters={setMarketTypesFilter} 
          usersFilters={cfdSettingsFilter}
          setUserFilters={setCfdSettingsFilter}
          tabName="Market" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
        />
      ),
      cell: (row) => row.type.name,
    },
    {
      name: 'Shift',
      selector: ({ shift }) => shift,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Leverage',
      selector: (row) => row.leverage,
      sortable: true,
      width: '120px',
    },
    {
      name: 'Lot Step',
      selector: (row) => row.lotStep,
      sortable: true,
    },
    {
      name: 'Lot Size',
      selector: (row) => row.lotSize,
      sortable: true,
    },
    {
      name: 'Commission %',
      selector: (row) => row.commission,
      sortable: true,
      width: '155px',
    },
    {
      name: 'Swap Long %',
      selector: (row) => row.swapLong,
      sortable: true,
      width: '155px',
    },
    {
      name: 'Swap Short %',
      selector: (row) => row.swapShort,
      sortable: true,
      width: '155px',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div className="action-btn">
          <button
            type="button"
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({
              headerContent: <h3>Edit CFD Setting</h3>, 
              bodyContent: <CfdSettingsModal row={row} />,
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>
      ),
    },
  ];

  return columns;
};
