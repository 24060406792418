import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { getAffiliateRule, deleteAffiliateRule } from '../redux/affiliateRule/affiliateRuleActions';

export const AffiliateRuleColumns = () => {
  const dispatch = useDispatch();

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const handleAffiliateRuleDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then(async (result) => {
      if (result.isConfirmed === true) {
        await dispatch(deleteAffiliateRule(id));
      }
    });
  };

  const ruleColumns = [
    {
      name: 'UID',
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice(row?._id.length || 0 - 4, row?._id.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: 'Rule',
      selector: (row) => <span>{`${row?.key} ${row?.isEqual ? ' is' : ' is not'} "${row?.value}"`}</span>,
      sortable: true,
    },
    {
      name: 'Assign To',
      selector: (row) => {
        if (Object.keys(row?.assignTo)?.length > 0) {
          return (
            <Link to={`/edit-admin/${row?.assignTo?._id}`} className="text-decoration-none">
              {`${row?.assignTo?.firstName} ${row?.assignTo?.lastName}`}
            </Link>
          );
        }
        return '-';
      },
      sortable: true,
    },
    {
      name: 'Action(s)',
      cell: (row) => (
        <>
          <button type="button" className="btn btn-success btn-sm me-1 p-1" onClick={() => dispatch(getAffiliateRule(row?._id))}>Edit</button>
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => handleAffiliateRuleDelete(row?._id)}>Remove</button>
        </>
      ),
    },
  ];
  return ruleColumns;
};
